
import { mapGetters } from 'vuex';

import { isBundleRegions } from '@/utils/bundleRegions';
import Gtm from '@/utils/gtm';
import { navigateToCheckout } from '@/utils/navigation';
import utils from '@/utils/utils';

export default {
  name: 'HomeSectionCTA',
  filters: {
    currency: utils.currency
  },
  data() {
    return {
      isLoading: true
    };
  },
  computed: {
    ...mapGetters({
      promoDetails: 'getPromoDetails',
      campaign: 'getCampaign',
      partner: 'getParceiro',
      logoPorto: 'getLogoPorto',
      getPartnerCode: 'getPartnerCode',
      plans: 'getPlans',
      getPromoCode: 'getPromoCode',
      showBundleAddOn: 'getShowBundleAddOn',
      discountClubPrice: 'additionalServices/getDiscountClubPrice',
      discountEcommercePrice: 'additionalServices/getDiscountClubEcommercePrice'
    }),
    isPromotion() {
      return !!this.promoDetails?.slug;
    },
    callbackStatus() {
      return this.$store.getters.getCallbackStatus;
    },
    videoId() {
      return '1vMumJWw-Q0';
    },
    title() {
      return this.isPromotion && this.promoDetails?.title
        ? this.promoDetails.title
        : 'PLANO DE SAÚDE PET';
    },
    subtitle() {
      return this.isPromotion && this.promoDetails?.subtitle
        ? this.promoDetails.subtitle
        : 'Uma vida mais longa e saudável para o seu pet';
    },
    bgImage() {
      return this.isPromotion && this.promoDetails?.image_path
        ? this.promoDetails.image_path
        : require('@/assets/images/backgrounds/home-cta.jpg');
    },
    bgUrl() {
      return require('~/assets/images/backgrounds/home-cta.jpg');
    },
    showInfoTopics() {
      const isUtmAndPartner = this.partner?.status && !!this.getPromoCode;

      return !isUtmAndPartner && !this.promoDetails?.image_description;
    },
    hasDiscountClubOffer() {
      const hasPlans =
        Array.isArray(this.plans) &&
        this.plans.some((plan) => plan.discount_club_offer === true);

      return hasPlans;
    },
    showAddOn() {
      return this.showBundleAddOn && isBundleRegions();
    }
  },
  async mounted() {
    await this.showInfoTopics;
    await this.hasDiscountClubOffer;

    this.isLoading = false;
  },
  methods: {
    goToContrate($event) {
      this.sendGtmEvent('banner-lp', $event);

      location.href = navigateToCheckout({
        params: {
          from: 'mainCTA',
          ...this.$route.query
        }
      });
    },
    openVideo($event) {
      this.sendGtmEvent('como-funciona', $event);
      this.$nuxt.$emit('show:video', this.videoId);
    },
    sendGtmEvent(btnName, event) {
      Gtm.sendLPHomeEvent(`${btnName}:botao`, event.target.textContent.trim());
    }
  }
};
