import { render, staticRenderFns } from "./HomeSectionCTA.vue?vue&type=template&id=fc155036&scoped=true"
import script from "./HomeSectionCTA.vue?vue&type=script&lang=js"
export * from "./HomeSectionCTA.vue?vue&type=script&lang=js"
import style0 from "./HomeSectionCTA.vue?vue&type=style&index=0&id=fc155036&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc155036",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CaIcon: require('/app/node_modules/@petlove/caramelo-vue-components/src/Icon/index.vue').default,CaButton: require('/app/node_modules/@petlove/caramelo-vue-components/src/Button/index.vue').default,CaCard: require('/app/node_modules/@petlove/caramelo-vue-components/src/Card/index.vue').default})
